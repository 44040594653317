import { API_URL } from "../../../utils/apiConfig";
import { SearchFilters } from "./constants";

const ITEMS_API = 'regulations/items';
const ITEM = '?searchText=';
const ITEMDESCRIPTION = '/description?searchText=';
const SERIES = '/series?searchText=';
const MARKET_API = 'market?searchText='
const OFFICE_API = 'vendor/offices?searchText='
const COLLECTIVECLASS_API = 'collective-class?searchText='
const Vendor_API = 'vendor?searchText='
const REGULATION_STATUS_API = 'regulations/status';
const DASHBOARD_API = "regulations/dashboard"
const SITE_API = 'site/get-sites?searchText='

export const DashboardApi = () => {
    return API_URL + DASHBOARD_API;
}

export const GetFilterAPIUrl = (filter, seriesFilterKey) => {
    switch (filter) {
        case SearchFilters.Series:
            return API_URL + ITEMS_API + SERIES + seriesFilterKey;

        case SearchFilters.ItemSKU:
            return API_URL + ITEMS_API + ITEM + seriesFilterKey;

        case SearchFilters.ItemDescription:
            return API_URL + ITEMS_API + ITEMDESCRIPTION + seriesFilterKey;

        case SearchFilters.Market:
            return API_URL + MARKET_API + seriesFilterKey;

        case SearchFilters.Office:
            return API_URL + OFFICE_API + seriesFilterKey;

        case SearchFilters.CollectiveClass:
            return API_URL + COLLECTIVECLASS_API + seriesFilterKey;

        case SearchFilters.Vendor:
            return API_URL + Vendor_API + seriesFilterKey;

        case SearchFilters.Site:
            return API_URL + SITE_API + seriesFilterKey;

        case SearchFilters.Compliance:
            return API_URL + REGULATION_STATUS_API;

        default:
            return '';
    }
}