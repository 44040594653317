import {
    FetchDashboardInfoByFilter, DispatchSeriesFilters, DispatchMarketFilters, DispatchResetPagination,
    DispatchCollectiveClassFilters, DispatchOfficeFilters, DispatchItemDescriptionFilters, DispatchItemFilters, DispatchVendorFilters,
    DispatchComplianceFilters, DispatchCompliantIdFilters, DispatchSiteFilters
} from "../actionCreators/searchEngineAction"

export const MapStateToFilterProps = (state, ownProps) => {
    return {
        itemFilters: state.searchEngineReducer.itemFilters,
        itemDescriptionFilters: state.searchEngineReducer.itemDescriptionFilters,
        seriesFilters: state.searchEngineReducer.seriesFilters,
        marketFilters: state.searchEngineReducer.marketFilters,
        officeFilters: state.searchEngineReducer.officeFilters,
        vendorFilters: state.searchEngineReducer.vendorFilters,
        siteFilters: state.searchEngineReducer.siteFilters,
        complianceFilters: state.searchEngineReducer.complianceFilters,
        compliantIdFilters: state.searchEngineReducer.compliantIdFilters,
        collectiveClassFilters: state.searchEngineReducer.collectiveClassFilters,
        isReset: state.searchEngineReducer.isReset,
        isVendorView: state.levelZeroReducer.isVendorView
    };
};

export const MapDispatchToFilterProps = (dispatch, ownProps) => {
    return {
        dispatchDashboardFilter: (filterData) => dispatch(FetchDashboardInfoByFilter(filterData)),
        dispatchItemFilters: (itemFilters) => dispatch(DispatchItemFilters(itemFilters)),
        dispatchItemDescriptionFilters: (itemDescriptionFilters) => dispatch(DispatchItemDescriptionFilters(itemDescriptionFilters)),
        dispatchSeriesFilters: (seriesFilters) => dispatch(DispatchSeriesFilters(seriesFilters)),
        dispatchMarketFilters: (marketFilters) => dispatch(DispatchMarketFilters(marketFilters)),
        dispatchCollectiveClassFilters: (collectiveClassFilters) => dispatch(DispatchCollectiveClassFilters(collectiveClassFilters)),
        dispatchOfficeFilters: (officeFilters) => dispatch(DispatchOfficeFilters(officeFilters)),
        dispatchVendorFilters: (vendorFilters) => dispatch(DispatchVendorFilters(vendorFilters)),
        dispatchSiteFilters: (siteFilters) => dispatch(DispatchSiteFilters(siteFilters)),
        dispatchComplianceFilters: (complianceFilters) => dispatch(DispatchComplianceFilters(complianceFilters)),
        dispatchCompliantIdFilters: (compliantIdFilters) => dispatch(DispatchCompliantIdFilters(compliantIdFilters)),
        dispatchResetPagination: (isReset) => dispatch(DispatchResetPagination(isReset))
    }
};