import React from "react";
import { ACCESS_DENIED_MESSAGE, NOT_FOUND } from "../utils/constant";


const NoMatch = props => {


    return (
        <div >
            <h2 style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.54)" }}>
                {NOT_FOUND}
            </h2>
            <h5 style={{ textAlign: "center", color: "rgba(0, 0, 0, 0.54)" }}>
                {ACCESS_DENIED_MESSAGE}
            </h5>
        </div>
    );
};



export default NoMatch;
