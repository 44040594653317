



import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";

const PopoverComponent = props => {

    const { anchor, offset, show, handleClosePopup, infoInformation } = props;
    const [layerStyle, setLayerStyle] = useState(null);
    const handleClose = () => {
        setLayerStyle(0);
        handleClosePopup();
    }

    const elementOffset = (el) => {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    const [elementOffsets, SetElementOffsets] = useState({
        top: 20,
        left: 0,
    });


    useEffect(() => {
        var div = document.querySelector('.filter-icon');
        var divOffset = elementOffset(div);
        setLayerStyle("");
        SetElementOffsets({
            top: divOffset.top + 40,
            left: divOffset.left,
        })

    }, []);


    useEffect(() => {
        var div = document.querySelector('.filter-icon');
        var divOffset = elementOffset(div);
        if (show) {
            setLayerStyle("");
            SetElementOffsets({
                top: divOffset.top + 40,
                left: divOffset.left,
            })
        } else {
            setLayerStyle("none");
        }
    }, [show]);

    return (
        <div style={{
            display: layerStyle,
            zIndex: 2,
            height: "100%",
            width: "100%",
            background: "transparent",
            top: elementOffsets.top,
            left: elementOffsets.left,
            position: "fixed"
        }}>
            <Popup
                anchor={anchor}
                offset={offset}
                show={show}
                popupClass={"popup-content"}>
                <div>
                    <div>
                        <Button
                            icon="close"
                            look="flat"
                            onClick={handleClose}
                            style={{
                                marginTop: "-30px",
                                right: "0px",
                                position: "absolute"
                            }}
                        >
                        </Button>
                    </div>
                    {ReactHtmlParser(infoInformation)}
                </div>
            </Popup>
            <style>
                {
                    `
        .popup-content {
            padding: 30px;
            color: #787878;
            border: 1px solid rgba(0, 0, 0, .05);
        }`
                }
            </style>

        </div>
    );
}

export default PopoverComponent;


