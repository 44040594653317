import { AjaxAPI } from '../../../utils/ajaxAPI';
import { GetFilterAPIUrl, DashboardApi } from '../utils/urlConstant';
import { ActionTypes } from '../../dashboard/utils/constants';
import { SearchEngineActionTypes } from '../utils/constants';
import { ExceptionHandler } from '../../../utils/exceptionHandler';
import {
    CloseLoaderIndicator
} from '../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction';

export async function FetchFilters(seriesFilterKey, filter) {
    const ajaxCalls = new AjaxAPI();
    const url = GetFilterAPIUrl(filter, encodeURIComponent(seriesFilterKey));
    const response = await ajaxCalls.getMethod(url);

    if (response.ok) {
        const json = await response.json();
        return json;
    }

    const exceptionHandler = new ExceptionHandler();
    await exceptionHandler.ShowErrorAlert(response);
    return [];
}

export function FetchDashboardInfoByFilter(data) {
    return async function (dispatch) {
        const ajaxCalls = new AjaxAPI();
        const url = DashboardApi();

        const response = await ajaxCalls.postMethod(url, data);
        const json = await response.json();

        if (!response.ok) {
            const exceptionHandler = new ExceptionHandler();
            await exceptionHandler.ShowErrorAlert(response);

            CloseLoaderIndicator();
            dispatch(dispatchFilterStatus(false));
            dispatch(dispatchItemsByFilter(json));
        }

        if (data.filters.length > 0 && data.filters[0].searchText && data.filters[0].searchText.length > 0) {
            dispatch(dispatchFilterStatus(true));
        }
        else {
            dispatch(dispatchFilterStatus(false));
        }
        CloseLoaderIndicator();
        return dispatch(dispatchItemsByFilter(json));
    };
}

export const DispatchItemFilters = (itemFilters) => {
    return {
        type: SearchEngineActionTypes.itemFilters, itemFilters
    };
}

export const DispatchItemDescriptionFilters = (itemDescriptionFilters) => {
    return {
        type: SearchEngineActionTypes.itemDescriptionFilters, itemDescriptionFilters
    };
}

export const DispatchVendorFilters = (vendorFilters) => {
    return {
        type: SearchEngineActionTypes.vendorFilters, vendorFilters
    };
}

export const DispatchSiteFilters = (siteFilters) => {
    return {
        type: SearchEngineActionTypes.siteFilters, siteFilters
    };
}

export const DispatchCompliantIdFilters = (compliantIdFilters) => {

    return {
        type: SearchEngineActionTypes.compliantIdFilters, compliantIdFilters
    };
}

export const DispatchComplianceFilters = (complianceFilters) => {
    return {
        type: SearchEngineActionTypes.complianceFilters, complianceFilters
    };
}

export const DispatchSeriesFilters = (seriesFilters) => {
    return {
        type: SearchEngineActionTypes.seriesFilters, seriesFilters
    };
}
export const DispatchMarketFilters = (marketFilters) => {
    return {
        type: SearchEngineActionTypes.marketFilters, marketFilters
    };
}

export const DispatchCollectiveClassFilters = (collectiveClassFilters) => {
    return {
        type: SearchEngineActionTypes.collectiveClassFilters, collectiveClassFilters
    };
}

export const DispatchOfficeFilters = (officeFilters) => {
    return {
        type: SearchEngineActionTypes.officeFilters, officeFilters
    };
}

export const DispatchResetPagination = (isReset) => {
    return {
        type: SearchEngineActionTypes.isReset, isReset
    };
}

const dispatchFilterStatus = (isfiltered) => {
    return {
        type: ActionTypes.isfiltered, isfiltered
    };
}

const dispatchItemsByFilter = (items) => {
    return {
        type: ActionTypes.items, items
    };
}

