
import GridComponent from '../../../shared-components/shared-controls/grid'


import React, { useState, useEffect, useRef } from "react";

import {
    GridProperties, GridPageProperties,
} from '../utils/constants'
import PopoverComponent from "../../../shared-components/shared-controls/popOver";
import {
    ShowLoaderIndicator, CloseLoaderIndicator
} from '../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction';
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';
import {
    RegulationResults, NOT_APPLICABLE,

} from '../../../utils/appStringConstants';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { InfoControls, RegulationStatusIndicators } from '../utils/constants';
import { FetchLevelOneRegulations } from '../actionCreators/LevelOneAction';
import { MapDispatchToLevelZeroProps, MapStateToLevelZeroProps } from '../utils/levelZeroPropMapping';



const HeaderCellWithCustomStyle = (props) => {
    const headerStyle = {
        fontSize: "14px"

    }
    return (
        <span style={headerStyle}>{props.title}</span>
    );
}



const LevelOneComponent = (props) => {


    const CellWithBackGround = (props) => {

        let fields = props.field;

        let fieldContent = props.dataItem.cells.find(cell => {
            return cell.columnId === fields
        })



        let hoverContents = fieldContent ? fieldContent.hoverContent : null
        let popupControl = false;
        let popOverContent;

        if (fieldContent && fieldContent.indicatorType && fieldContent.indicatorType
            === InfoControls.PopOver) {
            
            hoverContents = null;
            popupControl = true;
            popOverContent = fieldContent.hoverContent;


        }

        let strokeWidth = "0";
        let cellColor = RegulationStatusIndicators.defaultIndicator;
        let category = props.dataItem[props.field];


        if (category === RegulationResults.compliant) {
            cellColor = RegulationStatusIndicators.compliant;

        } else if (category === RegulationResults.expired) {
            cellColor = RegulationStatusIndicators.expired;
        } else if (category === RegulationResults.notUpdated) {
            cellColor = RegulationStatusIndicators.notUpdated;
        } else if (category === RegulationResults.comingDue) {
            cellColor = RegulationStatusIndicators.comingDue;
        }
        else if (category === RegulationResults.notApplicable) {
            cellColor = RegulationStatusIndicators.notApplicable;
            strokeWidth = "0.5";
        }

        const shapeStyle = {

            fill: cellColor,
            stroke: "gray",
            'strokeWidth': strokeWidth,
            opacity: "1"
        }

        const textStyle = {
            fill: "gray",
            fontSize: "12",
            fontWeight: "bold"
        }

        return (
            <td

                style={{
                    cursor: popupControl ? "pointer" : "unset"
                }}
                title={hoverContents}
            >
                <span onClick={(e) => {
                    handlePopup(e, popupControl, popOverContent)

                }}>

                    <svg width="80" height="20" >
                        <g>
                            <rect x="0" y="0" rx="10" ry="10" width="80" height="20"
                                style={shapeStyle} />
                            {
                                (category === RegulationResults.notApplicable) &&
                                <text x="31" y="15" style={textStyle} >{NOT_APPLICABLE}</text>
                            }
                        </g>
                    </svg>
                </span>
            </td>
        );

    }



    const [rowId, setRowId] = useState(1);
    const [infoInformation, setInfoInformation] = useState(null);


    const [gridData, setGridData] = useState([]);
    const [dataState, setDataState] = useState({});
    const [gridColumns, setGridColumns] = useState([]);
    const gridDiv = useRef(null);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {

        let childGridInfo = props.dataItem.details;


        if (childGridInfo && childGridInfo?.data &&
            childGridInfo.data.columns.length > 0) {

            let gridColumn = [];
            childGridInfo.data.columns.map((x, index) => {
                if (x.visibleIndex > -1) {
                    if (index === 0) {

                        gridColumn.push(
                            {
                                field: x.id,
                                title: x.text,
                                cell: CustomCell,
                                expandable: x.isExpandable,
                                headerCell: HeaderCellWithCustomStyle,
                                isRegulation: x.isRegulation,
                                className: x.id.toString(),

                            }
                        );

                    } else {

                        gridColumn.push(
                            {
                                field: x.id,
                                title: x.text,
                                cell: CellWithBackGround,
                                expandable: x.isExpandable,
                                headerCell: HeaderCellWithCustomStyle,
                                className: x.id.toString(),
                                isRegulation: x.isRegulation,

                            }
                        );
                    }

                }
            });

            setGridColumns(gridColumn);
            setTotalCount(childGridInfo.totalCount)
        }

        if (childGridInfo && childGridInfo.data.rows) {

            let gridRows = []
            let rowIds = rowId;
            childGridInfo.data.rows.map((element) => {
                let singleRow = {}
                element.cells.forEach((innerElement, index2) => {
                    singleRow[innerElement.columnId] = innerElement.text
                    if (element.isExpandable) {
                        singleRow.expandable = element.isExpandable;
                    }
                    singleRow.id = rowIds;
                });
                singleRow = { ...singleRow, ...element }

                if (element.isExpandable) {
                    singleRow.regulations = [{}];
                }
                setRowId(rowIds);
                gridRows.push(singleRow)

            });

            setGridData((gridRows) ? gridRows : []);
            removeTreeIcon();
        }

    }, [props.dataItem.details]);


    const removeTreeIcon = () => {
        setTimeout(() => {
            document.querySelectorAll(".expandclasshide").forEach(element => {
                element.previousSibling.innerHTML = "";
            });

        }, 100);
    }




    const CustomCell = (props) => {

        const { dataItem } = props;

        let hoverContents;


        let fieldContent = dataItem.cells.find(cell => {
            return cell.columnId === props.field.trim();
        });

        if (fieldContent && fieldContent.hoverContent) {
            hoverContents = String(fieldContent.hoverContent);
        }

        const data = dataItem[props.field];
        let dataAsString = '';

        if (data !== undefined && data !== null) {
            dataAsString = data.toString();
        }

        let expandclass = "expandclasshide";
        if (props.dataItem.isExpandable) {

            if (props.dataItem.isExpandable) {
                expandclass = "expandclass";

            } else {
                expandclass = "expandclasshide";

            }
        }



        return (
            <td
                title={hoverContents}
                className={props.className, expandclass}
                colSpan={props.colSpan}
            >

                {dataAsString}
            </td>
        );
    }

    const handleExpandChange = async (event) => {


        if (event.dataItem.isExpandable) {

            const isExpanded =
                event.dataItem.expanded === undefined ?
                    event.dataItem.aggregates : event.dataItem.expanded;

            event.dataItem.expanded = !isExpanded;

            const gridItems = gridData.slice();
            const id = gridItems.findIndex(d => JSON.stringify(d) === JSON.stringify(event.dataItem));

            if (event.value && !gridItems[id].details) {
                if (event.dataItem.nextLevelUrl) {
                    ShowLoaderIndicator();
                    gridItems[id].details = await FetchLevelOneRegulations(event.dataItem.nextLevelUrl.requestBody);
                    CloseLoaderIndicator();
                }
            }
            setGridData(gridItems);
            removeTreeIcon();
        }

    }





    const handleDataStateChange = (event) => {

        let nextUrl = props.dataItem.nextLevelUrl.requestBody;
        nextUrl.pageNo = event.data.skip = 0 ? 0 : parseInt(event.data.skip / event.data.take);
        nextUrl.pageSize = event.data.take;


        ShowLoaderIndicator();
        event.data.skip = (event.data.skip * event.data.take);
        setDataState(event.data);
        pageWiseDataCallBack(nextUrl);

    }



    const pageWiseDataCallBack = async (data) => {

        const datas = await FetchLevelOneRegulations(data);
        pageWiseData(datas)
        CloseLoaderIndicator();
    }

    const pageWiseData = (param) => {
        ShowLoaderIndicator();
        let childGridInfo = param;

        if (childGridInfo && childGridInfo?.data &&
            childGridInfo.data.columns.length > 0) {
            let gridColumn = [];
            childGridInfo.data.columns.map((x, index) => {
                if (x.visibleIndex > -1) {
                    if (index === 0) {

                        gridColumn.push(
                            {
                                field: x.id,
                                title: x.text,
                                cell: CustomCell,
                                expandable: x.isExpandable,
                                headerCell: HeaderCellWithCustomStyle,
                                isRegulation: x.isRegulation,
                                className: x.id.toString(),
                                expandable: false,
                                isExpandable: false,


                            }
                        );

                    } else {

                        gridColumn.push(
                            {
                                field: x.id,
                                title: x.text,
                                cell: CellWithBackGround,
                                expandable: x.isExpandable,
                                headerCell: HeaderCellWithCustomStyle,
                                className: x.id.toString(),
                                isRegulation: x.isRegulation,

                            }
                        );
                    }

                }
            });
            setGridColumns(gridColumn);
            setTotalCount(childGridInfo.totalCount)
        }

        if (childGridInfo && childGridInfo.data.rows) {

            let gridRows = []
            let rowIds = rowId;
            childGridInfo.data.rows.map((element) => {
                let singleRow = {}
                element.cells.forEach((innerElement, index2) => {
                    singleRow[innerElement.columnId] = innerElement.text
                    if (element.isExpandable) {
                        singleRow.expandable = element.isExpandable;
                    }
                    singleRow.id = rowIds;
                });
                singleRow = { ...singleRow, ...element }

                if (element.isExpandable) {
                    singleRow.regulations = [{}];
                }
                setRowId(rowIds);
                gridRows.push(singleRow)



            });
            setGridData((gridRows) ? gridRows : []);
            removeTreeIcon();
        }

        CloseLoaderIndicator();
    }

    const anchor = null;

    const [show, setShow] = React.useState(false);
    const [offset, setOffset] = useState({});

    const handlePopup = (e, popupControl, popOverContent) => {

        let cursorX = parseInt(e.pageX) + 50;
        let cursorY = e.pageY;

        setOffset(
            {
                left: cursorX,
                top: cursorY,
            }
        )

        setInfoInformation(popOverContent);
        setShow(popupControl);
    };

    const handleClosePopup = () => {

        if (show) {
            setShow(false);
        }

    }

    return <>
        <div ref={gridDiv}>
            <PopoverComponent
                anchor={anchor}
                offset={offset}
                show={show}
                handleClosePopup={handleClosePopup}
                infoInformation={infoInformation}
            />
            <Tooltip openDelay={100}
                content={(props) => {
                    return ReactHtmlParser(props && props.title ? props.title : "")
                }}

                position="right" anchorElement="element">

                <GridComponent

                    gridColumns={gridColumns}
                    gridData={gridData}
                    dataState={dataState}
                    detail={LevelOneComponent}
                    expandField="expanded"
                    onExpandChange={handleExpandChange}
                    onDataStateChange={handleDataStateChange}
                    gridProperties={GridProperties}
                    gridPageProperties={GridPageProperties}


                    total={totalCount}
                    pageable={true}
                    pageable={GridPageProperties}
                />
            </Tooltip>
        </div>
    </>


};

export default connect(MapStateToLevelZeroProps, MapDispatchToLevelZeroProps)(LevelOneComponent);