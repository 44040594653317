import React, { useEffect } from "react";
import { connect } from "react-redux";

import { MapStateToHomeProps, MapDispatchToHomeProps } from "../utils/homePropMapping";

import { Drawer, DrawerContent } from "@progress/kendo-react-layout";
import { CommonMenu, MasterItems } from "./menuList";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import "./styles.css";
import { Link } from "react-router-dom";



const MobileView = (props) => {

    const [expanded, setExpanded] = React.useState(false);
    useEffect(() => {
        setExpanded(props.show);
    }, [props.show])


    const CustomItem = () => {

        const onSelect = () => {
            props.setShow(false);
        };

        return (
            <span className="contact-item">
                <div className="panelbar-wrapper panelbar-wrapper-menu">
                    <PanelBar >
                        <PanelBarItem title="Master">
                            {
                                MasterItems.map((element) => {
                                    return (
                                        <Link to={element.route} onClick={onSelect} customProp={element.route} className="sideMenuItems" >
                                            <PanelBarItem title={element.text} customProp={element.route} />
                                        </Link>)
                                })
                            }
                        </PanelBarItem>
                        {
                            CommonMenu.map((element) => {
                                return (
                                    <Link to={element} onClick={onSelect} customProp={element.route} className="sideMenuItems" >
                                        <PanelBarItem title={element} customProp={element.route} />
                                    </Link>)
                            })
                        }
                    </PanelBar>
                </div>
                <style>
                    {
                        `
                       .contact-item .k-content{
                        padding: 10px !important;
                        text-decoration: none !important;
                        }
        `
                    }
                </style>
            </span>

        );
    };



    return (
        <div>
            <Drawer
                expanded={expanded}
                position={"start"}
                width={"300"}
                mode={'overlay'}
                animation={{
                    duration: 400,
                }}
                onOverlayClick={props.handleClick}
                items={MasterItems.map((item) => ({
                    ...item,
                }))}
                item={CustomItem}
            >
                <DrawerContent>{props.children}</DrawerContent>
            </Drawer>
        </div>
    );
};


export default connect(MapStateToHomeProps, MapDispatchToHomeProps)(MobileView);

