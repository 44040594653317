import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';
import Dashboard from "../../modules/dashboard/components/dashboardIndex";

import { Button, DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import logo from "../../utils/images/Logo.png";
import AlertComponent from "../../shared-components/alerts/components/alertComponent";
import LoaderIndicatorComponent from "../../shared-components/page-loader-indicators/components/loader";
import { MapStateToHomeProps, MapDispatchToHomeProps } from "../utils/homePropMapping";
import Regulation from "../../modules/master/regulation/components/regulationIndex";
import MobileView from "./mobileView";
import { CommonMenu, MasterItems } from "./menuList";
import { withRouter } from "react-router-dom";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import { HashRouter } from "react-router-dom";

import { Link } from "react-router-dom";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NoMatch from "./noMatch";


const HomePage = props => {

  const [show, setShow] = React.useState(false);
  const anchor = React.useRef(null);

  const { isAlertShow } = props;

  const handleClick = () => {
    setShow(!show);
  }


  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const itemRender = (props) => {
    return (
      <div>
        <Link className="menuItems" style={{ textDecoration: "none" }} to={props.item.route}>
          <span role="presentation" >
            {props.item.text}
          </span>
        </Link>
      </div>
    );
  };

  return (
    <React.Fragment>

      {
        isAlertShow && <AlertComponent />
      }

      <LoaderIndicatorComponent />
      <BrowserRouter >
        <AppBar
          themeColor="dark"
          className="app-header">
          <AppBarSection className="title">
            <Link to="/dashboard/Dashboard">
              <img src={logo} alt="logo"
                style={{ width: 150, cursor: "pointer" }} className="ashley-logo" />
            </Link>

          </AppBarSection>
          <AppBarSpacer style={{ width: "2%" }} />

          <AppBarSection className="appbar-items">

            {
              windowSize.width > 872 &&
              <>
                <DropDownButton
                  className={"menuButton"}
                  buttonClass={"menuButton"}
                  dir="rtl"
                  items={MasterItems}
                  itemRender={itemRender}
                  icon="chevron-down"
                  text="Master" >

                </DropDownButton>

                &nbsp;&nbsp;
                {
                  CommonMenu.map((Element) => {
                    return (<>
                      <Link to={Element} className={"menuItems"} style={{ textDecoration: "none" }}>
                        <Button buttonClass={"menuButton"} className={"menuButton"}>{Element}</Button>&nbsp;&nbsp;
                      </Link>
                    </>);
                  })
                }
              </>
            }

            {
              windowSize.width > 768 && windowSize.width < 871 &&
              <Button
                style={{
                  right: 0,
                  position: 'absolute'
                }}
                className="menuButton"
                look={"flat"}
                onClick={handleClick}
              >
                <span className="k-icon k-i-menu" />
              </Button>
            }

          </AppBarSection>
          <AppBarSpacer />
          <AppBarSection>
            {
              windowSize.width < 768 &&
              <Button
                className="menuButton"
                ref={anchor}
                look={"flat"}
                onClick={handleClick}
              >
                <span className="k-icon k-i-menu" />
              </Button>
            }
          </AppBarSection>
        </AppBar>
        <MobileView
          show={show}
          setShow={setShow}
          handleClick={handleClick}
        />


        <Routes>
          <Route
            exact
            path="/"
            element={<Dashboard />}
          />
          <Route exact path="/dashboard/Dashboard" element={<Dashboard />} />
          <Route exact path="/regulation" element={<Regulation />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>

      <style>{`
      .menuItems:hover{
        text-decoration: "none" !important;
      }
       .k-panelbar > .k-item > .k-link.k-state-selected {
        color: white;
    }
      	.menuButton {
          text-transform: capitalize !important;
        }
        
        body {
          background: ##FFFFFF;
        }
        
        .title {
          font-size: 15px;
          padding-left: 12px;
          padding-top: 5px;
          color: #696969;
        }
        
        .ashley-logo {
          marginRight: 2%;
        }
        
        .app-header {
          background: #D3D3D3;
        }
        
        @media only screen and (max-width: 600px) {
          .appbar-items,
          .user-actions,
          .k-avatar,
          .k-appbar-separator {
            display: none;
          }
          .overflow-button {
            display: block;
          }
          .content ul li:last-child {
            display: block;
          }
          .k-grid table {
            width: 100% !important;
          }
          .grid-items {
            height: 60vh;
            width: 100%;
          }
          .search-btn {
            margin-left: 55%;
          }
        }
        
        
        /* Small devices (portrait tablets and large phones, 600px and up) */
        
        @media only screen and (min-width: 600px) {
          .appbar-items,
          .user-actions,
          .k-avatar,
          .k-appbar-separator {
            display: none;
          }
          .overflow-button {
            display: block;
          }
          .content ul li:last-child {
            display: none;
          }
          .grid-items {
            height: 65vh;
          }
          .k-grid table {
            width: 100% !important;
          }
          .search-btn {
            margin-left: 55%;
          }
        }
        
        
        /* Medium devices (landscape tablets, 768px and up) */
        
        @media only screen and (min-width: 768px) {
          .appbar-items,
          .user-actions,
          .k-appbar-separator,
          .k-avatar {
            display: block;
          }
          .overflow-button {
            display: none;
          }
          .grid-items {
            height: 70vh;
          }
          .k-grid table {
            width: 100% !important;
          }
          .search-btn {
            margin-left: 55%;
          }
        }
        
        
        /* Large devices (laptops/desktops, 992px and up) */
        
        @media only screen and (min-width: 992px) {
          .grid-items {
            height: 75vh;
          }
          .k-grid table {
            width: 100% !important;
          }
          .search-btn {
            margin-left: 55%;
          }
          /* Larger devices (desktops, 1400px and up) */
          @media only screen and (min-width: 1400px) {
            .grid-items {
              height: 86vh;
            }
            .k-grid table {
              width: 100% !important;
            }
            .search-btn {
              margin-left: 65%;
            }
          }
            `}</style>

    </React.Fragment>
  );
}


export default connect(MapStateToHomeProps, MapDispatchToHomeProps)(HomePage);

