import { SearchEngineActionTypes } from '../utils/constants'

const initialFilterState = {
    isReset: false,
    itemFilters: [],
    itemDescriptionFilters: [],
    seriesFilters: [],
    marketFilters: [],
    collectiveClassFilters: [],
    officeFilters: [],
    vendorFilters: [],
    siteFilters: [],
    complianceFilters: [],
    compliantIdFilters: []
};

const searchEngineReducer = (state = initialFilterState, action) => {
    switch (action.type) {
        case SearchEngineActionTypes.itemFilters:
            return {
                ...state, itemFilters: action.itemFilters
            };

        case SearchEngineActionTypes.itemDescriptionFilters:
            return {
                ...state, itemDescriptionFilters: action.itemDescriptionFilters
            };

        case SearchEngineActionTypes.marketFilters:
            return {
                ...state, marketFilters: action.marketFilters
            };

        case SearchEngineActionTypes.seriesFilters:
            return {
                ...state, seriesFilters: action.seriesFilters
            };

        case SearchEngineActionTypes.collectiveClassFilters:
            return {
                ...state, collectiveClassFilters: action.collectiveClassFilters
            };

        case SearchEngineActionTypes.officeFilters:
            return {
                ...state, officeFilters: action.officeFilters
            };

        case SearchEngineActionTypes.vendorFilters:
            return {
                ...state, vendorFilters: action.vendorFilters
            };

        case SearchEngineActionTypes.siteFilters:
            return {
                ...state, siteFilters: action.siteFilters
            };

        case SearchEngineActionTypes.complianceFilters:
            return {
                ...state, complianceFilters: action.complianceFilters
            };

        case SearchEngineActionTypes.compliantIdFilters:
            return {
                ...state, compliantIdFilters: action.compliantIdFilters
            };

        case SearchEngineActionTypes.isReset:
            return {
                ...state, isReset: action.isReset
            };
        default:
            return state;
    }
}

export default searchEngineReducer;