import {
    REGULATORY_MATRIX, COMMUNICATIONS, VENDOR_MANAGEMENT, TESTING, REGULATORY_MANAGEMENT
  } from "../../utils/appStringConstants"
  
  export const MasterItems = [
    {
      text: "Regulation",
      route: "/regulation",
    }
  ];
  export const CommonMenu = [
    REGULATORY_MATRIX,
    COMMUNICATIONS,
    VENDOR_MANAGEMENT,
    TESTING,
    REGULATORY_MANAGEMENT
  ];
  
  