import { AjaxAPI } from '../../../utils/ajaxAPI';
import { DashboardApi } from '../utils/urlConstant';
import { ExceptionHandler } from '../../../utils/exceptionHandler';


export async function FetchLevelOneRegulations(param) {
    const ajaxCalls = new AjaxAPI();
    const baseUrl = DashboardApi();

    const response = await ajaxCalls.postMethod(baseUrl, param);

    if (!response.ok) {
        const exceptionHandler = new ExceptionHandler();
        await exceptionHandler.ShowErrorAlert(response);
        return {};
    }

    const json = await response.json();
    return json
}
