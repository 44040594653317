import React, { useEffect, useState } from "react";
import { Splitter } from '@progress/kendo-react-layout';
import LevelZeroComponent from './levelZeroComponent';
import SearchEngine from '../../searchEngine/components/searchEngineComponent';

const Dashboard = props => {

    const [isMobileView, setIsMobileView] = useState(true);
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    const [panes, setPanes] = useState([
        {

            size: isMobileView ? '50%' : '20%',
            resizable: false,
            collapsible: true, scrollable: false, collapsed: true
        }
    ]);

    const handlePaneChange = (event) => {
        setPanes(event.newState);
    }





    useEffect(() => {
        if (windowSize.width <= 770) {
            setIsMobileView(true);
        } else {
            setIsMobileView(false);
        }
    }, [windowSize])

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleFilterClick = () => {

        if (panes[0].collapsed === true) {
          
            setPanes([
                {
                    size: isMobileView ? '50%' : '20%', collapsible: true, scrollable: false, collapsed: false,
                    resizable: false,
                }
            ]);
        }
        else {
          
            setPanes([
                {
                    size: isMobileView ? '50%' : '20%', collapsible: true, scrollable: false, collapsed: true,
                    resizable: false,
                }
            ]);
        }
    }

    return (
        <React.Fragment>

            <Splitter
                style={{ height: "100%", width: "100%", overflow: 'hidden' }}
                panes={panes}

                keepMounted={true}
                onChange={handlePaneChange}


            >
                <div style={{
                    height: windowSize && windowSize.height - 60,
                    overflowX: "hidden",
                    overflowY: "auto"
                }}>
                    <SearchEngine />
                </div>
                <div style={{
                    margin: "0px",
                    width: "100%",
                    overflow: 'hidden'
                }} >
                    <LevelZeroComponent
                        windowSize={windowSize}
                        handleFilterClick={handleFilterClick}
                    />
                </div>
            </Splitter>

        </React.Fragment>

    );
}
export default Dashboard;


