import { combineReducers } from "redux";

import levelZeroReducer from "../modules/dashboard/reducers/levelZeroReducer";
import vendorReducer from "../modules/dashboard/reducers/vendorReducer";
import regulationReducer from "../modules/master/regulation/reducers/regulationReducer";
import searchEngineReducer from "../modules/searchEngine/reducers/searchEngineReducer";
import alertReducer from "../shared-components/alerts/reducers/alertReducer";
import loaderIndicatorReducer from "../shared-components/page-loader-indicators/reducers/loaderIndicatorReducer";

const rootReducer = combineReducers({
  levelZeroReducer: levelZeroReducer,
  vendorReducer: vendorReducer,
  searchEngineReducer: searchEngineReducer,
  alertReducer: alertReducer,
  loaderIndicatorReducer: loaderIndicatorReducer,
  regulationReducer:regulationReducer
});

export default rootReducer;
