import {
    DispatchCollectiveClassFilters, DispatchComplianceFilters, DispatchCompliantIdFilters, DispatchItemDescriptionFilters, DispatchItemFilters,
    DispatchMarketFilters, DispatchOfficeFilters, DispatchResetPagination, DispatchSeriesFilters, DispatchVendorFilters, DispatchSiteFilters
} from "../../searchEngine/actionCreators/searchEngineAction";
import { DispatchVendorView, FetchLevel0Regulations } from "../actionCreators/LevelZeroAction";

export const MapStateToLevelZeroProps = (state, ownProps) => {
    return {
        items: state.levelZeroReducer.items,
        itemFilters: state.searchEngineReducer.itemFilters,
        itemDescriptionFilters: state.searchEngineReducer.itemDescriptionFilters,
        seriesFilters: state.searchEngineReducer.seriesFilters,
        marketFilters: state.searchEngineReducer.marketFilters,
        officeFilters: state.searchEngineReducer.officeFilters,
        collectiveClassFilters: state.searchEngineReducer.collectiveClassFilters,
        isfiltered: state.levelZeroReducer.isfiltered,
        isReset: state.searchEngineReducer.isReset,
        userSelectPage: state.levelZeroReducer.userSelectPage,
        isVendorView: state.levelZeroReducer.isVendorView,
        vendorFilters: state.searchEngineReducer.vendorFilters,
        complianceFilters: state.searchEngineReducer.complianceFilters,
        compliantIdFilters: state.searchEngineReducer.compliantIdFilters,
    };
};

export const MapDispatchToLevelZeroProps = (dispatch, ownProps) => {
    return {
        dispatchLevel0Regulations: async (data) => dispatch(FetchLevel0Regulations(data)),
        handleFilterClick: () => ownProps.handleFilterClick(),
        dispatchItemFilters: (itemFilters) => dispatch(DispatchItemFilters(itemFilters)),
        dispatchItemDescriptionFilters: (itemDescriptionFilters) => dispatch(DispatchItemDescriptionFilters(itemDescriptionFilters)),
        dispatchSeriesFilters: (seriesFilters) => dispatch(DispatchSeriesFilters(seriesFilters)),
        dispatchVendorView: (isVendorViewChecked) => dispatch(DispatchVendorView(isVendorViewChecked)),
        dispatchMarketFilters: (marketFilters) => dispatch(DispatchMarketFilters(marketFilters)),
        dispatchVendorFilters: (vendorFilters) => dispatch(DispatchVendorFilters(vendorFilters)),
        dispatchComplianceFilters: (complianceFilters) => dispatch(DispatchComplianceFilters(complianceFilters)),
        dispatchCompliantIdFilters: (compliantIdFilters) => dispatch(DispatchCompliantIdFilters(compliantIdFilters)),
        dispatchCollectiveClassFilters: (collectiveClassFilters) => dispatch(DispatchCollectiveClassFilters(collectiveClassFilters)),
        dispatchOfficeFilters: (officeFilters) => dispatch(DispatchOfficeFilters(officeFilters)),
        dispatchSiteFilters: (siteFilters) => dispatch(DispatchSiteFilters(siteFilters)),
        dispatchResetPagination: (isReset) => dispatch(DispatchResetPagination(isReset))
    }
};




