import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { filterBy } from '@progress/kendo-data-query';
import { FetchFilters } from '../actionCreators/searchEngineAction';
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import MultiSelector from '../../../shared-components/shared-controls/multiSelector';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { MapDispatchToFilterProps, MapStateToFilterProps } from "../../searchEngine/utils/searchEnginePropsMapping";
import { GridProperties, viewTypeSearch } from "../../dashboard/utils/constants";
import {
    ITEM_SKU, SortOrders, SERIES_TITLE, MARKET_TITLE, OFFICE_TITLE, COLLECTIVE_CLASS_TITLE, CLEAR, SEARCH, ITEM_DESCRIPTION,
    ITEM_TITLE, VENDOR_TITLE, COMPLIANCE_TITLE, SITE
} from '../../../utils/appStringConstants';
import { MAX_SERIES_SEARCH_TEXT, SearchFilters, DELAY } from '../utils/constants';
import {
    ShowLoaderIndicator
} from '../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction';


const SearchEngine = (props) => {

    const timeout = React.useRef();

    const { dispatchItemFilters, dispatchDashboardFilter, dispatchResetPagination, dispatchSiteFilters,
        dispatchItemDescriptionFilters, dispatchSeriesFilters, dispatchMarketFilters,
        dispatchCollectiveClassFilters, dispatchOfficeFilters, dispatchVendorFilters, dispatchComplianceFilters, dispatchCompliantIdFilters } = props;

    const { itemFilters, itemDescriptionFilters, seriesFilters, officeFilters, collectiveClassFilters, marketFilters, vendorFilters, complianceFilters,
        compliantIdFilters, isVendorView, siteFilters } = props;

    const [expand, setExpand] = useState(false);
    const [itemData, setItemData] = useState([]);
    const [itemDescriptionData, setItemDescriptionData] = useState([]);
    const [seriesData, setSeriesData] = useState([]);
    const [officeData, setOfficeData] = useState([]);
    const [marketData, setMarketData] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const [siteData, setSiteData] = useState([]);
    const [complianceData, setComplianceData] = useState([]);
    const [collectiveClassData, setCollectiveClassData] = useState([]);
    const [isItemLoading, setIsItemLoading] = useState(false);
    const [isItemDescriptionLoading, setIsItemDescriptionLoading] = useState(false);
    const [isSeriesLoading, setIsSeriesLoading] = useState(false);
    const [isOfficeLoading, setIsOfficeLoading] = useState(false);
    const [isMarketLoading, setIsMarketLoading] = useState(false);
    const [isCollectiveClassLoading, setIsCollectiveClassLoading] = useState(false);
    const [isVendorLoading, setIsVendorLoading] = useState(false);

    useEffect(() => {
        async function fetchDefaultValues() {
            await fetchFilters("", SearchFilters.Site);
            await fetchFilters("", "Compliance");
        }
        fetchDefaultValues();

    }, [])

    const clearFilterData = (value, changeFrom) => {

        switch (changeFrom) {
            case SearchFilters.Series:
                if (value.length === 0 && seriesData.length > 0) {
                    setSeriesData([]);
                }
            case SearchFilters.ItemSKU:
                if (value.length === 0 && itemData.length > 0) {
                    setItemData([]);
                }
            case SearchFilters.ItemDescription:
                if (value.length === 0 && itemDescriptionData.length > 0) {
                    setItemDescriptionData([]);
                }
            case SearchFilters.Market:
                if (value.length === 0 && marketData.length > 0) {
                    setMarketData([]);
                }
            case SearchFilters.Office:
                if (value.length === 0 && officeData.length > 0) {
                    setOfficeData([]);
                }
            case SearchFilters.CollectiveClass:
                if (value.length === 0 && collectiveClassData.length > 0) {
                    setCollectiveClassData([]);
                }
            case SearchFilters.Vendor:
                if (value.length === 0 && vendorData.length > 0) {
                    setVendorData([]);
                }

            case SearchFilters.Compliance:
                if (value.length === 0 && complianceData.length > 0) {
                }
        }
    }

    const fetchFilters = async (text, changeFrom) => {
        EnableLoaders(changeFrom);

        const response = await FetchFilters(text, changeFrom);
        mapFilterData(response, changeFrom);
    }

    const EnableLoaders = (changeFrom) => {
        switch (changeFrom) {
            case SearchFilters.Series:
                setIsSeriesLoading(true);
                break;

            case SearchFilters.ItemSKU:
                setIsItemLoading(true);
                break;

            case SearchFilters.ItemDescription:
                setIsItemDescriptionLoading(true);
                break;

            case SearchFilters.Market:
                setIsMarketLoading(true);
                break;

            case SearchFilters.Office:
                setIsOfficeLoading(true);
                break;

            case SearchFilters.CollectiveClass:
                setIsCollectiveClassLoading(true);
                break;

            case SearchFilters.Vendor:
                setIsVendorLoading(true);
                break;

            case SearchFilters.Site:
                break;

            case SearchFilters.Compliance:
                break;
        }
    }

    const mapFilterData = (value, changeFrom) => {
        switch (changeFrom) {
            case SearchFilters.Series:
                setSeriesData(value);
                setIsSeriesLoading(false);
                break;

            case SearchFilters.ItemSKU:
                setItemData(value);
                setIsItemLoading(false);
                break;

            case SearchFilters.ItemDescription:
                setItemDescriptionData(value);
                setIsItemDescriptionLoading(false);
                break;

            case SearchFilters.Market:
                setMarketData(value);
                setIsMarketLoading(false);
                break;

            case SearchFilters.Office:
                setOfficeData(value);
                setIsOfficeLoading(false);
                break;

            case SearchFilters.CollectiveClass:
                setCollectiveClassData(value);
                setIsCollectiveClassLoading(false);
                break;

            case SearchFilters.Vendor:
                setVendorData(value);
                setIsVendorLoading(false);
                break;

            case SearchFilters.Site:
                setSiteData(value);

            case SearchFilters.Compliance:
                setComplianceData(value);
                break;
        }
    }

    const handleFilterChange = (event, changeFrom) => {
        clearTimeout(timeout.current);

        timeout.current = setTimeout(async () => {
            await handleFilterChangeField(event, changeFrom)
        }, DELAY);
    }

    const handleFilterChangeField = async (event, changeFrom) => {

        clearFilterData(event.filter.value, changeFrom);

        if (seriesData.length === 0 &&
            ((changeFrom === SearchFilters.Vendor && event.filter.value.length >= MAX_SERIES_SEARCH_TEXT)
                || (changeFrom !== SearchFilters.Vendor && event.filter.value.length > MAX_SERIES_SEARCH_TEXT))) {

            await fetchFilters(event.filter.value, changeFrom);
        }
        else if (seriesData.length > 0 &&
            (changeFrom === SearchFilters.Vendor && event.filter.value.length >= MAX_SERIES_SEARCH_TEXT)
            || (changeFrom !== SearchFilters.Vendor && event.filter.value.length > MAX_SERIES_SEARCH_TEXT)) {

            const filtered = filterBy(seriesData, event.filter);
            mapFilterData(filtered, changeFrom);
        }
    }

    const handleSelectedOfficeFilterChange = (event) => {

        dispatchResetPagination(false);
        dispatchOfficeFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleSelectedClassFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchCollectiveClassFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleSelectedSeriesFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchSeriesFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleSelectedItemFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchItemFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleSelectedItemDescriptionFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchItemDescriptionFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleSelectedVendorFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchVendorFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleSelectedSiteFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchSiteFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleSelectedComplianceFilterChange = (event) => {
        dispatchResetPagination(false);
        let a = event.target.value ? [...event.target.value] : [];
        dispatchComplianceFilters(a);
        dispatchCompliantIdFilters(complianceData.filter(value => a.indexOf(value.compliantName) !== -1).map(x => x.compliantId.toString()));
    }

    const handleSelectedMarketFilterChange = (event) => {
        dispatchResetPagination(false);
        dispatchMarketFilters(event.target.value ? [...event.target.value] : []);
    }

    const handleReset = (event) => {
        event.preventDefault();
        ShowLoaderIndicator();
        dispatchItemDescriptionFilters([]);
        dispatchItemFilters([]);
        dispatchVendorFilters([]);
        dispatchComplianceFilters([]);
        dispatchCompliantIdFilters([]);
        dispatchSeriesFilters([]);
        dispatchMarketFilters([]);
        dispatchCollectiveClassFilters([]);
        dispatchOfficeFilters([]);
        dispatchSiteFilters([]);

        dispatchResetPagination(true);
        setExpand(false);

        let data = {
            "pageNo": GridProperties.startIndex,
            "pageSize": GridProperties.endIndex,
            "orderBy": isVendorView ? undefined : ITEM_SKU,
            "orderDirection": SortOrders.ascending,
            "filters": [
                {
                    "fieldName": "level",
                    "searchText": [
                        "0"
                    ]
                },
                {
                    "fieldName": "ViewBased",
                    "searchText": [
                        isVendorView ? viewTypeSearch.vendor : viewTypeSearch.item
                    ]
                }
            ]
        }
        dispatchResetPagination(true);
        dispatchDashboardFilter(data);
    }

    const handleSearch = (event) => {

        event.preventDefault();
        ShowLoaderIndicator();

        let data = {
            "pageNo": GridProperties.startIndex,
            "pageSize": GridProperties.endIndex,
            "orderBy": isVendorView ? undefined : ITEM_SKU,
            "orderDirection": '',
            "filters": [
                {
                    "fieldName": "level",
                    "searchText": [
                        "0"
                    ]
                },
                {
                    "fieldName": "ViewBased",
                    "searchText": [
                        isVendorView ? viewTypeSearch.vendor : viewTypeSearch.item
                    ]
                }
            ]
        }
        if (itemFilters && itemFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.ItemSKU,
                searchText: itemFilters
            });
        }
        if (itemDescriptionFilters && itemDescriptionFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.ItemDescription,
                searchText: itemDescriptionFilters
            });
        }
        if (seriesFilters && seriesFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Series,
                searchText: seriesFilters
            });
        } if (marketFilters && marketFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Market,
                searchText: marketFilters
            });
        }
        if (officeFilters && officeFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Office,
                searchText: officeFilters
            });
        }
        if (collectiveClassFilters && collectiveClassFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.CollectiveClass,
                searchText: collectiveClassFilters
            });
        }
        if (vendorFilters && vendorFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Vendor,
                searchText: vendorFilters.map(x => x.split(' | ')[0])
            });
        }
        if (siteFilters && siteFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Site,
                searchText: siteFilters
            });
        }
        if (compliantIdFilters && compliantIdFilters.length > 0) {
            data.filters.push({
                fieldName: SearchFilters.Compliance,
                searchText: compliantIdFilters
            });
        }

        dispatchResetPagination(true);
        dispatchDashboardFilter(data);
    }

    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <React.Fragment>
            <div>

                <div className="">

                    <div className="panelbar-wrapper panel-item">
                        <PanelBar  >
                            <PanelBarItem expanded={expand} title={SERIES_TITLE}>
                                <MultiSelector
                                    data={seriesData}
                                    onChange={handleFilterChange}
                                    filterType={SearchFilters.Series}
                                    isLoading={isSeriesLoading}
                                    onSelectedChange={handleSelectedSeriesFilterChange}
                                    defaultValue={seriesFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={expand} title={ITEM_TITLE}>
                                <MultiSelector
                                    data={itemData}
                                    onChange={handleFilterChange}

                                    filterType={SearchFilters.ItemSKU}
                                    isLoading={isItemLoading}
                                    onSelectedChange={handleSelectedItemFilterChange}
                                    defaultValue={itemFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={expand} title={ITEM_DESCRIPTION}>
                                <MultiSelector
                                    data={itemDescriptionData}
                                    onChange={handleFilterChange}

                                    filterType={SearchFilters.ItemDescription}
                                    isLoading={isItemDescriptionLoading}
                                    onSelectedChange={handleSelectedItemDescriptionFilterChange}
                                    defaultValue={itemDescriptionFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={expand} title={MARKET_TITLE} >
                                <MultiSelector
                                    data={marketData}
                                    onChange={handleFilterChange}
                                    filterType={SearchFilters.Market}
                                    isLoading={isMarketLoading}
                                    onSelectedChange={handleSelectedMarketFilterChange}
                                    defaultValue={marketFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={expand} title={COLLECTIVE_CLASS_TITLE} >
                                <MultiSelector
                                    data={collectiveClassData}
                                    onChange={handleFilterChange}
                                    isLoading={isCollectiveClassLoading}
                                    filterType={SearchFilters.CollectiveClass}
                                    onSelectedChange={handleSelectedClassFilterChange}
                                    defaultValue={collectiveClassFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={expand} title={OFFICE_TITLE} >
                                <MultiSelector
                                    data={officeData}
                                    onChange={handleFilterChange}
                                    isLoading={isOfficeLoading}
                                    filterType={SearchFilters.Office}
                                    onSelectedChange={handleSelectedOfficeFilterChange}
                                    defaultValue={officeFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={expand} title={VENDOR_TITLE} >
                                <MultiSelector
                                    data={vendorData}
                                    onChange={handleFilterChange}
                                    isLoading={isVendorLoading}
                                    filterType={SearchFilters.Vendor}
                                    onSelectedChange={handleSelectedVendorFilterChange}
                                    defaultValue={vendorFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={expand} title={SITE} >
                                <MultiSelect
                                    data={siteData}
                                    onChange={handleSelectedSiteFilterChange}
                                    value={siteFilters}
                                />
                            </PanelBarItem>
                            <PanelBarItem expanded={expand} title={COMPLIANCE_TITLE} >
                                <MultiSelect
                                    data={complianceData.map(x => x.compliantName)}
                                    onChange={handleSelectedComplianceFilterChange}
                                    value={complianceFilters}
                                />

                            </PanelBarItem>
                        </PanelBar>
                    </div>

                </div>
                <div className="row">

                    <div className="col-lg-6 col-xs-6 col-sm-6">
                        <Button

                            style={{
                                margin: "10px",
                            }}
                            className="searchButton"
                            onClick={handleReset} >
                            {CLEAR}
                        </Button>
                    </div>
                    <div className="col-lg-6 col-xs-6 col-sm-6">
                        <Button
                            style={{
                                margin: "10px",
                                right: "12px",
                                position: "absolute",
                                marginTop: windowSize.width < 768 ? "-45px" : "10px"

                            }}
                            className="searchButton"
                            primary={true}
                            onClick={handleSearch} >
                            {SEARCH}
                        </Button>
                    </div>
                </div>
            </div>

            <style>{`
            
             .searchButton{
                text-transform: capitalize !important;
              }
            .example-col{
                padding-left:7px;
            }
            .search-btn{
              
                            
               "background-color": "#CCCCCC";
            }

            .panel-item{
               padding-Top: 10%; 
               padding-Bottom: 5%;
               margin-left: 0% ;
            }
            .k-panelbar > .k-item > .k-link.k-state-selected, .k-panelbar > .k-panelbar-header > .k-link.k-state-selected {
                color: white !important; 
            }
            .k-panelbar > .k-item > .k-link{
                color: #e51a5f !important; 
            }
            `}
            </style>
        </React.Fragment >
    );
}

export default connect(MapStateToFilterProps, MapDispatchToFilterProps)(SearchEngine);

