import { COMING_DUE, COMPLAINT, EXPIRED, NOT_UPDATED } from "../../../utils/appStringConstants";

export const ITEMS = "itemSKU";
export const COMPLAINCE = "complianceStatusId";
export const COLLECTIVE_CLASS = "collectiveClassName";
export const MARKET = "marketName";
export const OFFICE = "office";
export const ETD = "etd";
export const ETA = "eta";
export const DESCRIPTION = "itemDescription";

export const ITEM_VIEW = "Item View";
export const VENDOR_VIEW = "Vendor View";
export const VIEW_BY = "View By";
export const viewTypeSearch = {
    item: "ITEM",
    vendor: "VENDOR"
}

export const ActionTypes = {
    items: "ITEMS",
    vendorDetail: "VENDOR",
    isVendorDetailDialogBoxShown: "ISVENDORDETAILDIALOGBOXSHOWN",
    vendorRegulations: "VENDORREGULATIONS",
    isfiltered: "ISFILTERED",
    isVendorView: "IS_VENDOR_VIEW",
    userSelectPage: "PAGE_CHANGE"
}

export const GridProperties = {
    startIndex: 0,
    endIndex: 10,
}

export const GridPageProperties = {
    buttonCount: 10,
    pageSizes: [10, 20, 50]
}

export const RegulationStatusIndicators = {
    defaultIndicator: "rgba(0, 0, 0, 0.12)",
    compliant: "rgb(55, 180, 0,0.32)",
    expired: "rgb(243, 23, 0, 0.32)",
    notUpdated: "#cccccc",
    comingDue: "#fffcbb",
    notApplicable: "transparent"
}
export const complainceWithColor = {
    NOT_UPDATED: "#CCCCCC",
    COMPLAINT: "rgb(55, 180, 0,0.32)",
    COMING_DUE: "#D4D756",
    EXPIRED: "rgb(243, 23, 0, 0.32)",
}

export const complaince = [
    { text: NOT_UPDATED, "bgColor": "#CCCCCC" },
    { text: COMPLAINT, "bgColor": "rgb(55, 180, 0,0.32)" },
    { text: COMING_DUE, "bgColor": "#D4D756" },
    { text: EXPIRED, "bgColor": "rgb(243, 23, 0, 0.32)" },
]

export const TreeListProperties = {
    site: "site",
    finishedGoodVendor: "vendor",
    component: "Item Component",
    componentVendors: "Vendor Item Component",
    regulations: "regulations"
}

export const InfoControls = {
    PopOver: "PopOver",
}

export const ViewByOptions = [
    "Item",
    "Vendor",
]
export const ITEM = "Item";
export const VENDOR = "Vendor";