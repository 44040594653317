export const TITLE = "Regulatory Dashboard";
export const COLLECTIVE_CLASS_TITLE = "Collective Class";
export const SEARCH = "Search";
export const CLEAR = "Clear";
export const ITEM_TITLE = "Item";
export const ITEM_DESCRIPTION = "Item Description";
export const SERIES_TITLE = "Series";
export const MARKET_TITLE = "Market";
export const OFFICE_TITLE = "Office";
export const VENDOR_TITLE = "Vendor";
export const COMPLIANCE_TITLE = "Compliance";
export const VENDOR_NAME_TITLE = "Vendor Name";
export const VENDOR_DETAIL_TITLE = "Vendor Detail";
export const ETD_TITLE = "ETD";
export const ETA_TITLE = "ETA";
export const COMPLAINT = "Compliant";
export const COMING_DUE = "Coming Due";
export const EXPIRED = "Expired/Non Compliant";
export const NOT_UPDATED = "Not Updated";
export const REGULATORY_MATRIX = "Regulatory Matrix";
export const COMMUNICATIONS = "Communications";
export const VENDOR_MANAGEMENT = "Vendor Management";
export const TESTING = "Testing";
export const REGULATORY_MANAGEMENT = "Regulatory Management";
export const DASHBOARD = "Dashboard";

export const RegulationResults =
{
    compliant: "1",
    expired: "2",
    notUpdated: "3",
    comingDue: "4",
    notApplicable: "5"

}

export const Hierarchy ={
    level_0:"0",
    level_1:"1",
    level_2:"2",
    level_3:"3"
}

export const Views ={
    items:"ITEM"
}

export const FilterFields ={
    level:"level",
    ViewBased:"ViewBased"

}

export const NOT_APPLICABLE = "NA";
export const SITE = "Site";
export const SITE_VENDOR = "Site / Vendor";
export const VENDOR = "vendor"
export const ITEM_SKU = "ItemSKU"

export const SortOrders =
{
    ascending: "asc",
    descending: "desc",
}