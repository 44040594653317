import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import GridComponent from '../../../shared-components/shared-controls/grid'
import { ITEM_SKU, SortOrders, Hierarchy, FilterFields } from '../../../utils/appStringConstants';
import { MapDispatchToLevelZeroProps, MapStateToLevelZeroProps } from "../utils/levelZeroPropMapping";
import { Button, Chip, DropDownButton, FloatingActionButton } from '@progress/kendo-react-buttons';
import {
    RegulationResults
} from '../../../utils/appStringConstants';
import {
    GridProperties, GridPageProperties, viewTypeSearch, ViewByOptions, ITEM, VENDOR, complaince, VIEW_BY
} from '../utils/constants'
import { FetchLevelOneRegulations } from '../actionCreators/LevelZeroAction';
import {
    ShowLoaderIndicator, CloseLoaderIndicator
} from '../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction';
import { SearchFilters } from "../../searchEngine/utils/constants";
import LevelOneComponent from "./levelOneComponent";
import { Label } from "@progress/kendo-react-labels";

const CellWithBackGround = (props) => {
    const complaince = props.dataItem[props.field];
    let color1 = "rgb(55, 180, 0,0.32)";
    let color2 = "rgb(243, 23, 0, 0.32)";
    let color3 = "#CCCCCC";
    let color4 = "#D4D756";
    let cellColor = "rgba(0, 0, 0, 0.12)";
    if (complaince === RegulationResults.compliant) {
        cellColor = color1;
    } else if (complaince === RegulationResults.expired) {
        cellColor = color2;
    } else if (complaince === RegulationResults.notUpdated) {
        cellColor = color3;
    } else if (complaince === RegulationResults.comingDue) {
        cellColor = color4;
    }

    const shapeStyle = {
        fill: cellColor,
        stroke: "gray",
        'strokeWidth': "1",
        opacity: "1"
    }
    return (
        <td >
            <svg width="100" height="30">
                <rect x="0" y="0" rx="0" ry="0" width="100" height="30"
                    style={shapeStyle} />
            </svg>
        </td>
    );
}

const HeaderCellWithCustomStyle = (props) => {
    const headerStyle = {
        fontSize: "14px"

    }
    return (
        <span style={headerStyle}>{props.title}</span>
    );
}

const CustomItem = (props) => {
    console.log("props", props)
    return (
        <span className="contact-item">
            <Chip
                text={props && props.item && props.item.text}
                value="chip"
                style={{
                    background: props && props.item && props.item.bgColor,
                    fontSize: 8,
                    width: 200
                }}
            />
        </span>
    );
};


const LevelZeroComponent = props => {
    const { vendorFilters, compliantIdFilters, itemFilters, itemDescriptionFilters, items, seriesFilters, officeFilters,
        collectiveClassFilters, marketFilters, isfiltered, isReset } = props;

    const { dispatchSeriesFilters, dispatchMarketFilters,
        dispatchCollectiveClassFilters, dispatchOfficeFilters, dispatchResetPagination, dispatchSiteFilters,
        dispatchItemFilters, dispatchItemDescriptionFilters, dispatchVendorFilters, dispatchCompliantIdFilters } = props;

    const { dispatchLevel0Regulations } = props;
    const { dispatchVendorView } = props;
    const { handleFilterClick } = props;
    const { windowSize } = props;

    const [gridData, setGridData] = useState([]);
    const [dataState, setDataState] = useState({});
    const [gridColumns, setGridColumns] = useState([]);
    const gridDiv = useRef(null);

    const handleDataStateChange = (event) => {
        ShowLoaderIndicator();
        let data = {
            "pageNo": event.data.skip = 0 ? 0 : parseInt(event.data.skip / event.data.take),
            "pageSize": event.data.take,

            "orderBy": isVendorViewChecked ? undefined : ITEM_SKU,
            "orderDirection": SortOrders.ascending,
            "filters": [
                {
                    "fieldName": FilterFields.level,
                    "searchText": [
                        Hierarchy.level_0
                    ]
                },
                {
                    "fieldName": FilterFields.ViewBased,
                    "searchText": [
                        isVendorViewChecked ? viewTypeSearch.vendor : viewTypeSearch.item
                    ]
                }
            ]
        }
        if (isfiltered) {
            if (itemFilters && itemFilters.length > 0) {
                data.filters.push({
                    fieldName: SearchFilters.ItemSKU,
                    searchText: itemFilters
                });
            }
            if (itemDescriptionFilters && itemDescriptionFilters.length > 0) {
                data.filters.push({
                    fieldName: SearchFilters.ItemDescription,
                    searchText: itemDescriptionFilters
                });
            }
            if (seriesFilters && seriesFilters.length > 0) {
                data.filters.push({
                    fieldName: SearchFilters.Series,
                    searchText: seriesFilters
                });
            } if (marketFilters && marketFilters.length > 0) {
                data.filters.push({
                    fieldName: SearchFilters.Market,
                    searchText: marketFilters
                });
            }
            if (officeFilters && officeFilters.length > 0) {
                data.filters.push({
                    fieldName: SearchFilters.Office,
                    searchText: officeFilters
                });
            }
            if (collectiveClassFilters && collectiveClassFilters.length > 0) {
                data.filters.push({
                    fieldName: SearchFilters.CollectiveClass,
                    searchText: collectiveClassFilters
                });
            }
            if (vendorFilters && vendorFilters.length > 0) {

                data.filters.push({
                    fieldName: SearchFilters.Vendor,
                    searchText: vendorFilters.map(x => x.split(' | ')[0])
                });
            }
            if (compliantIdFilters && compliantIdFilters.length > 0) {
                data.filters.push({
                    fieldName: SearchFilters.Compliance,
                    searchText: compliantIdFilters
                });
            }
        }
        dispatchLevel0Regulations(data);
        event.data.skip = (event.data.skip * event.data.take);

        setDataState(event.data);
    }

    const handleExpandChange = async (event) => {
        const isExpanded =
            event.dataItem.expanded === undefined ?
                event.dataItem.aggregates : event.dataItem.expanded;

        event.dataItem.expanded = !isExpanded;

        const gridItems = gridData.slice();
        const id = gridItems.findIndex(d => JSON.stringify(d) === JSON.stringify(event.dataItem));

        if (event.value && !gridItems[id].details) {
            ShowLoaderIndicator();
            gridItems[id].details = await FetchLevelOneRegulations(event.dataItem.nextLevelUrl.requestBody);
            CloseLoaderIndicator();
        }
        setGridData(gridItems);
    }

    useEffect(() => {
        ShowLoaderIndicator();
        dispatchLevel0Regulations(getInitialLoadData());
    }, []);

    useEffect(() => {

        if (props.items.data) {
            let gridColumn = [];
            props.items.data.columns.map((x) => {
                if (x.visibleIndex > -1) {
                    if (x.isRegulation) {
                        gridColumn.push(
                            { field: x.id, title: x.text, cell: CellWithBackGround, expandable: x.isExpandable, headerCell: HeaderCellWithCustomStyle }
                        );
                    }
                    else {
                        gridColumn.push(
                            { field: x.id, title: x.text, expandable: x.isExpandable, headerCell: HeaderCellWithCustomStyle }
                        );
                    }
                }
            });
            setGridColumns(gridColumn);

        }

        if (props.items.data && props.items.data.rows) {
            let rows = [];
            props.items.data.rows.map((x) => {
                const row = x.cells.reduce((acc, it) => {
                    acc[it.columnId] = acc[it.text] || it.text;

                    return acc;
                }, {});

                rows.push({ ...row, nextLevelUrl: x.nextLevelUrl });

            });
            setGridData((rows) ? rows : []);
        }

    }, [props.items]);
    
    useEffect(() => {
        if (isReset) {
            const gridDataState = {
                skip: 0,
                take: 10
            }
            setDataState(gridDataState);
        }
    }, [isReset])


    let iconColor = isfiltered ? "red" : "grey";

    const [isVendorViewChecked, setIsVendorViewChecked] = React.useState(false);


    const getInitialLoadData = (isVendorView) => {
        return {
            "pageNo": GridProperties.startIndex,
            "pageSize": GridProperties.endIndex,
            "orderBy": isVendorView ? undefined : ITEM_SKU,
            "orderDirection": SortOrders.ascending,
            "filters": [
                {
                    "fieldName": "level",
                    "searchText": [
                        "0"
                    ]
                },
                {
                    "fieldName": "ViewBased",
                    "searchText": [
                        isVendorView ? viewTypeSearch.vendor : viewTypeSearch.item
                    ]
                }
            ]
        }
    }

    const handleViewBy = (event) => {
        if (event.item !== selectedView) {
            if (event.item === VENDOR) {
                setSelectedView(VENDOR);
                ShowLoaderIndicator();
                dispatchVendorView(true);
                setIsVendorViewChecked(true);

                dispatchLevel0Regulations(getInitialLoadData(true));
            } else {
                setSelectedView(ITEM);
                ShowLoaderIndicator();
                dispatchVendorView(false);
                setIsVendorViewChecked(false);
                dispatchLevel0Regulations(getInitialLoadData(false));
            }

            ShowLoaderIndicator();
            dispatchSeriesFilters([]);
            dispatchMarketFilters([]);
            dispatchCollectiveClassFilters([]);
            dispatchItemFilters([]);
            dispatchItemDescriptionFilters([]);
            dispatchVendorFilters([]);
            dispatchCompliantIdFilters([]);
            dispatchOfficeFilters([]);
            dispatchSiteFilters([]);
            dispatchResetPagination(true);
        }

    }

    const [selectedView, setSelectedView] = useState(ITEM);


    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row example-wrapperr">
                    <div className="col-lg-1">
                        <div style={{ left: "0", paddingLeft: "1%", position: "absolute" }}>
                            <Button icon="k-icon  k-i-filter k-icon-64" togglable={true} look="clear"
                                iconColor={iconColor}
                                onClick={handleFilterClick} className="filter-icon"></Button>
                        </div>

                    </div>



                    <div className={windowSize.width < 770 ? "col-lg-9 d-flex align-items-center justify-content-center" :
                        "col-lg-10 d-flex align-items-center justify-content-center"}
                    >
                        {
                            windowSize.width > 770 &&
                            complaince.map((element) => {
                                return (<Label><div style={{
                                    marginTop: 10,
                                    background: element.bgColor,
                                    display: 'inline-block', fontWeight: "bold", color: "black"
                                }} className={"compliance"}></div>&nbsp;&nbsp;{element.text}&nbsp;&nbsp;</Label>)
                            })
                        }
                    </div>
                    <div className={windowSize.width < 770 ? "col-lg-1" : undefined}>

                        {
                            windowSize.width < 770 &&
                            <FloatingActionButton
                                icon={"info"}
                                buttonClass="floatingButtonClass"
                                className="floatingClass"
                                text={"Compliance"}
                                themeColor={"light"}
                                items={complaince}
                                item={CustomItem}
                                size={"small"}
                                popupSettings={{
                                    animate: false,
                                    popupClass: "contacts-list",
                                }}
                                align={{
                                    vertical: "top",
                                    horizontal: "start",
                                }}
                                positionMode={'absolute'}
                                alignOffset={{
                                    x: 40,
                                    y: 2
                                }}
                            />

                        }
                    </div>
                    <div className="col-lg-1">
                        <div style={{ right: "0", paddingRight: "1.5%", position: "absolute" }}>
                            <DropDownButton
                                dir="rtl"
                                buttonClass={"menuButton"}
                                icon="chevron-down"
                                look="flat"
                                width={100}
                                onItemClick={handleViewBy}
                                text={
                                    windowSize.width < 770 ? selectedView :
                                        VIEW_BY + " : " + selectedView}

                                items={ViewByOptions}
                            />
                        </div>
                    </div>
                </div>
                {windowSize.width < 770 && <br />}
            </div>

            {
                <div  ref={gridDiv}>
                    <GridComponent
                        windowSize={windowSize}
                        gridColumns={gridColumns}
                        total={(items?.totalCount) ?? 0}
                        gridData={gridData}
                        dataState={dataState}
                        detail={LevelOneComponent}
                        expandField="expanded"
                        onExpandChange={handleExpandChange}
                        onDataStateChange={handleDataStateChange}
                        gridProperties={GridProperties}
                        gridPageProperties={GridPageProperties}
                    />
                </div>
            }
            <style>{`
              .menuButton{
                text-transform: capitalize !important;
              }
            .compliance{
                width: 15px;
                height: 15px;
                display: 'inline-block';
                text-transform: capitalize !important;

            }
            .k-fab-text{
                text-transform: capitalize !important;
            }
            .floatingClass{
                z-index:100,
                text-transform: capitalize !important;
            }
            .contacts-list{
                background:white;
                padding-top:10px;
                padding-bottom:10px;
                text-transform: capitalize !important;
            }
            .contacts-list li.k-fab-item {
                z-index:101
                margin-bottom: 0;
                padding: 0;
                background:white;
                text-transform: capitalize !important;
            }
            .contacts-list .k-fab-items {
                padding: 0;
                width: 200px;
                background:white;
            }
            .contact-item {
                background:white;
                padding: 0px 10px;
                width: 100%;
                display: flex;
                align-items: center;
            }
            .contact-details {
                margin-left: 8px;
                display: flex;
                flex-direction: column;
                line-height: initial;
                background:white;
            }
            .contact-role {
                font-size: 10px;
                color: #0058e9;
                background:white;
            }
            .k-fab{
                font-size:8px
            }
            .k-panelbar > .k-item > .k-link.k-state-selected {
                color: white;
            }
            `}
            </style>

        </React.Fragment >

    );
}

export default connect(MapStateToLevelZeroProps, MapDispatchToLevelZeroProps)(LevelZeroComponent);


