import { ActionTypes } from '../utils/constants'

const initialItemsState = {
    items: [],
    isfiltered: false,
    isVendorView: false,
    userSelectPage: false,
};

const levelZeroReducer = (state = initialItemsState, action) => {
    switch (action.type) {
        case ActionTypes.items:
            return {
                ...state,
                items: action.items
            };

        case ActionTypes.isfiltered:
            return {
                ...state,
                isfiltered: action.isfiltered
            };
        case ActionTypes.isVendorView:
            return {
                ...state,
                isVendorView: action.isVendorView
            };
        case ActionTypes.userSelectPage:
            return {
                ...state,
                userSelectPage: action.userSelectPage
            };

        default:
            return state;
    }
}

export default levelZeroReducer;