import { AjaxAPI } from '../../../utils/ajaxAPI';
import { DashboardApi } from '../utils/urlConstant';
import { ActionTypes } from '../utils/constants';
import { ExceptionHandler } from '../../../utils/exceptionHandler';
import { CloseLoaderIndicator } from '../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction';


export function FetchLevel0Regulations(data) {
    return async function (dispatch) {

        const ajaxCalls = new AjaxAPI();
        const url = DashboardApi();
        const response = await ajaxCalls.postMethod(url, data);

        if (response.ok) {
            const json = await response.json();
            CloseLoaderIndicator();

            return dispatch(dispatchLevel0Regulations(json));
        }

        const exceptionHandler = new ExceptionHandler();
        await exceptionHandler.ShowErrorAlert(response);

        CloseLoaderIndicator();
        return [];
    }
}

const dispatchLevel0Regulations = (items) => {
    return {
        type: ActionTypes.items, items
    }
}

export const DispatchVendorView = (isVendorView) => {
    return {
        type: ActionTypes.isVendorView, isVendorView
    }
}


export async function FetchLevelOneRegulations(param) {
    const ajaxCalls = new AjaxAPI();
    const baseUrl = DashboardApi();

    const response = await ajaxCalls.postMethod(baseUrl, param);

    if (!response.ok) {
        const exceptionHandler = new ExceptionHandler();
        await exceptionHandler.ShowErrorAlert(response);
        return {};
    }

    const json = await response.json();
    return json
}
