import React from 'react';
import { connect } from "react-redux";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import '@progress/kendo-react-animation';

const GridComponent = props => {

    const { gridColumns, total, detail, gridPageProperties, gridData, dataState } = props;
    const { onExpandChange, onDataStateChange, windowSize } = props;

    return (
        <React.Fragment >

            <div className="row">
                <div className="col s12" style={{ width: "100%", overflow: "auto" }}>
                    {
                        <Grid className="grid-items"
                            style={{
                                width: windowSize && windowSize.width < 770 ? '1200px' : "100%",
                                height: windowSize && windowSize.width < 770 ? windowSize && windowSize.height - 100 : (gridData && gridData.length >= 10 ? "" : "auto")
                            }}
                            pageable={gridPageProperties}
                            total={total}
                            data={gridData}
                            {...dataState}
                            onDataStateChange={onDataStateChange}
                            placeholder="Grid"
                            data-testid="Grid"
                            detail={detail}
                            expandField="expanded"
                            scrollable="scrollable"
                            onExpandChange={onExpandChange}>

                            {
                                gridColumns.map((column, index) => {
                                    if (column.cell) {
                                        return <GridColumn field={column.field} title={column.title} cell={column.cell} headerCell={column.headerCell} key={index} className="grid-header" style={{ 'font-size': '14px' }} />;
                                    }
                                    else {
                                        return <GridColumn field={column.field} title={column.title} key={index} headerCell={column.headerCell} style={{ 'width': '100%' }} />;
                                    }
                                })
                            }

                        </Grid>
                    }
                </div>
            </div>
        </React.Fragment >
    );

}

const mapStateProps = (state, ownProps) => {
    return {
        gridColumns: ownProps.gridColumns,
        expandField: ownProps.expandField,
        detail: ownProps.detail,
        gridProperties: ownProps.gridProperties
    };
};
const dispatchToGridMethods = (dispatch, ownProps) => {

    return {
        onExpandChange: (event) => ownProps.onExpandChange(event),
        onDataStateChange: (event) => ownProps.onDataStateChange(event)
    }
}
export default connect(mapStateProps, dispatchToGridMethods)(GridComponent);