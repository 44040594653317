import { RegulationsApi } from '../utils/urlConstant';
import { ActionTypes } from '../utils/constants';
import { ExceptionHandler } from '../../../../utils/exceptionHandler';

import { DispatchAlertSuccess } from '../../../../shared-components/alerts/actionCreators/alertAction';
import { REGULATION_UPDATE_SUCCESS } from '../../../../utils/alertMessages';
import { CloseLoaderIndicator } from '../../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction';
import { AjaxAPI } from '../../../../utils/ajaxAPI';

export function FetchRegulations() {

    return async function (dispatch) {
        const ajaxCalls = new AjaxAPI();
        const url = RegulationsApi();
        const response = await ajaxCalls.getMethod(url);
        if (response.ok) {
            const json = await response.json();
            CloseLoaderIndicator();
            return dispatch(dispatchRegulations(json));
        }

        const exceptionHandler = new ExceptionHandler();
        await exceptionHandler.ShowErrorAlert(response);

        CloseLoaderIndicator();
        return [];
    }
}

// put

export function UpdateRegulations(data) {

    return async function (dispatch) {
        const ajaxCalls = new AjaxAPI();
        const url = RegulationsApi();
        const response = await ajaxCalls.putMethod(url, data);

        if (response.ok && response.status === 200) {
            DispatchAlertSuccess(REGULATION_UPDATE_SUCCESS);
            CloseLoaderIndicator();
            dispatch(FetchRegulations());
        } else {

            const exceptionHandler = new ExceptionHandler();
            await exceptionHandler.ShowErrorAlert(response);
            CloseLoaderIndicator();

        }
    }
}


const dispatchRegulations = (regulations) => {
    return {
        type: ActionTypes.regulations, regulations
    }
}



