
export const SearchEngineActionTypes = {
    isReset: "ISRESET",
    officeFilters: "OFFICEFILTERS",
    collectiveClassFilters: "COLLECTIVECLASSFILTERS",
    marketFilters: "MARKETFILTERS",
    seriesFilters: "SERIESFILTERS",
    itemFilters: "ITEMFILTERS",
    itemDescriptionFilters: "ITEMDESCRIPTIONFILTERS",
    vendorFilters: "VENDORFILTERS",
    siteFilters: "SITEFILTERS",
    complianceFilters: "COMPLIANCEFILTERS",
    compliantIdFilters: "COMPLIANTIDFILTERS",
}

export const MAX_SERIES_SEARCH_TEXT = 2;
export const DELAY = 500;
export const SearchFilters = {
    ItemSKU: "ItemSKU",
    ItemDescription: "ItemDescription",
    Series: "Series",
    Market: "Market",
    Office: "Office",
    CollectiveClass: "CollectiveClass",
    Vendor: "Vendor",
    Site: "Site",
    Compliance: "Compliance",
}