import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FetchRegulations, UpdateRegulations } from "../actionCreators/regulationAction"

import { filterBy } from '@progress/kendo-data-query';

import { Grid, GridColumn } from '@progress/kendo-react-grid';
import '@progress/kendo-react-animation';
import { GridActionCell } from "./gridActionCell.jsx";
import { regulationGrid } from "../utils/constants";
import { ShowLoaderIndicator } from "../../../../shared-components/page-loader-indicators/actionCreators/loaderIndicatorAction";


const Regulation = props => {

    const editField = "inEdit";
    const { dispatchLevel0Regulations } = props;
    const { regulations } = props;
    const { dispatchUpdateRegulations } = props;

    const [gridData, setGridData] = useState({
        data: [],
        filter: {
            logic: "and",
            filters: []
        }
    });
    const [buttonDisable, setButtonDisable] = useState(false)
    useEffect(() => {
        ShowLoaderIndicator();
        dispatchLevel0Regulations();
    }, [])

    useEffect(() => {
        setGridData({
            ...gridData,
            data: regulations
        });
    }, [regulations])

    const CommandCell = props => (
        <GridActionCell
            {...props}
            edit={enterEdit}
            buttonDisable={buttonDisable}
            update={update}
            cancel={cancel}
            editField={editField}
        />
    );

    const update = dataItem => {
        dataItem.inEdit = false;
        delete dataItem.inEdit;
        setButtonDisable(false);
        ShowLoaderIndicator();
        dispatchUpdateRegulations(dataItem);

    };

    
    const cancel = dataItem => {
        setButtonDisable(false);
        setGridData({
            ...gridData,
            data: regulations
        });
    };

    const enterEdit = dataItem => {
        setButtonDisable(true);
        setGridData({
            ...gridData,
            data: gridData.data.map(item =>
                item.regulationId === dataItem.regulationId ? { ...item, inEdit: true } : item
            )
        });

    };

    const itemChange = event => {
        const data = gridData.data.map(item =>
            item.regulationId === event.dataItem.regulationId
                ? { ...item, [event.field]: event.value }
                : item
        );
        setGridData({ ...gridData, data });
    };


    return (
        <React.Fragment>
            <Grid
                placeholder="Grid"
                scrollable="scrollable"

                data={filterBy(gridData.data, gridData.filter)}

                filterable
                filter={gridData.filter}

                onFilterChange={e => {

                    setGridData({
                        ...gridData,
                        filter: e.filter
                    });
                }}

                onItemChange={itemChange}
                editField={editField}
                sortable

            >
                <GridColumn field="regulationName" title={regulationGrid.regulationName} editable={false} />
                <GridColumn field="expiryNotificationInDays" filter="numeric" editor={"numeric"} title={regulationGrid.expiryNotificationInDays} />
                <GridColumn field="validityInDays" filter="numeric" editor={"numeric"} title={regulationGrid.validityInDays} />
                <GridColumn filterable={false} cell={CommandCell} width="200px" />
            </Grid>
        </React.Fragment>
    );
}


const mapStateToRegulationProps = (state, ownProps) => {
    return {
        regulations: state.regulationReducer.regulations,
    };
};
const dispatchToRegulationProps = (dispatch, ownProps) => {

    return {
        dispatchLevel0Regulations: async () => dispatch(FetchRegulations()),
        dispatchUpdateRegulations: async (data) => dispatch(UpdateRegulations(data)),
    }
}
export default connect(mapStateToRegulationProps, dispatchToRegulationProps)(Regulation);

